export default {
  name: 'ModalExit',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  methods: {
    emitClose () {
      this.$emit('close')
    },
    emitAccept () {
      this.$emit('accept')
    },
    emitSave () {
      this.$emit('save')
    },
  },
}
