import moment from 'moment'

const DEFAULT_LANDING = 'new-landing'
export default class LandingTemplate {
  id
  name
  content
  path
  preview
  show
  type
  htmlContent

  constructor () {
    this.id = null
    this.name = `${moment().format('YYYY-MM-DD-kkmmss')}-${DEFAULT_LANDING}`
    this.content = null
    this.path = 'landings'
    this.preview = ''
    this.show = false
    this.type = 0
    this.htmlContent = ''
  }

  loadData (landing) {
    this.id = landing.id
    this.name = landing.name
    this.content = landing.content
    this.path = landing.path
    this.show = true
    this.type = landing.type
    this.htmlContent = landing.html_content
  }
}
